<template>
  <div>
    <div class="container" align="left">
      <div class="d-inline-block mt-5">
        <h4 class="mb-4">
          <strong>{{ $t('CEPG_main_title') }}</strong>
        </h4>
        <p>
          {{ $t('CEPG_main_desc') }}
        </p>
        <h4 class="mt-5 mb-4">
          <strong>{{ $t('CEPG_title1') }}</strong>
        </h4>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('CEPG_subtitle1_1') }}</strong>
        </h5>
        <p>
          {{ $t('CEPG_sub1_1_desc1') }}
        </p>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('CEPG_subtitle1_2') }}</strong>
        </h5>
        <p>
          {{ $t('CEPG_sub1_2_desc1') }}
        </p>
        <p class="numText1"></p>
        <p class="numText1">{{ $t('CEPG_sub1_2_desc2') }}<br /></p>
        <p class="numText1">{{ $t('CEPG_sub1_2_desc3') }}<br /></p>
        <p class="numText1">{{ $t('CEPG_sub1_2_desc4') }}</p>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('CEPG_subtitle1_3') }}</strong>
        </h5>
        <p>{{ $t('CEPG_sub1_3_desc1') }}</p>
        <h4 class="mt-5 mb-4">
          <strong>{{ $t('CEPG_title2') }}</strong>
        </h4>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('CEPG_subtitle2_1') }}</strong>
        </h5>
        <p>{{ $t('CEPG_sub2_1_desc1') }}</p>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('CEPG_subtitle2_2') }}</strong>
        </h5>
        <p class="numText2">
          {{ $t('CEPG_sub2_2_desc1') }}
        </p>
        <p class="numText2">
          {{ $t('CEPG_sub2_2_desc2') }}
        </p>
        <p class="numText3">{{ $t('CEPG_sub2_2_desc3') }}<br /></p>

        <p class="numText3">{{ $t('CEPG_sub2_2_desc4') }}<br /></p>
        <p class="numText3">{{ $t('CEPG_sub2_2_desc5') }}<br /></p>
        <p class="numText3">{{ $t('CEPG_sub2_2_desc6') }}<br /><br /></p>
        <p class="numText3">{{ $t('CEPG_sub2_2_desc7') }}<br /></p>
        <p class="numText3">{{ $t('CEPG_sub2_2_desc8') }}<br /></p>
        <p class="numText3">
          {{ $t('CEPG_sub2_2_desc9') }}
        </p>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('CEPG_subtitle2_3') }}</strong>
        </h5>
        <p>{{ $t('CEPG_sub2_3_desc1') }}</p>
        <p class="numText2">{{ $t('CEPG_sub2_3_desc2') }}<br /></p>

        <p class="numText2">{{ $t('CEPG_sub2_3_desc3') }}<br /></p>

        <p class="numText2">{{ $t('CEPG_sub2_3_desc4') }}<br /></p>
        <p class="numText2">{{ $t('CEPG_sub2_3_desc5') }}<br /></p>

        <p class="numText2">
          {{ $t('CEPG_sub2_3_desc6') }}
        </p>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('CEPG_subtitle2_4') }}</strong>
        </h5>
        <p class="numText2">{{ $t('CEPG_sub2_4_desc1') }}<br /></p>

        <p class="numText2">{{ $t('CEPG_sub2_4_desc2') }}<br /></p>
        <p class="numText2">{{ $t('CEPG_sub2_4_desc3') }}</p>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('CEPG_subtitle2_5') }}</strong>
        </h5>
        <p class="numText2">{{ $t('CEPG_sub2_5_desc1') }}<br /></p>

        <p class="numText2">{{ $t('CEPG_sub2_5_desc2') }}<br /></p>
        <p class="numText2">
          {{ $t('CEPG_sub2_5_desc3') }}
        </p>
        <h4 class="mt-5 mb-4">
          <strong>{{ $t('CEPG_title3') }}</strong>
        </h4>
        <p>
          {{ $t('CEPG_desc2') }}
        </p>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('CEPG_subtitle3_1') }}</strong>
        </h5>
        <p class="numText2">{{ $t('CEPG_sub3_1_desc1') }}<br /></p>

        <p class="numText2">{{ $t('CEPG_sub3_1_desc2') }}<br /></p>

        <p class="numText2">
          {{ $t('CEPG_sub3_1_desc3') }}
        </p>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('CEPG_subtitle3_2') }}</strong>
        </h5>
        <p class="numText2">{{ $t('CEPG_sub3_2_desc1') }}<br /></p>

        <p class="numText2">
          {{ $t('CEPG_sub3_2_desc2') }}
        </p>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('CEPG_subtitle3_3') }}</strong>
        </h5>
        <p>
          {{ $t('CEPG_sub3_3_desc1') }}
        </p>
        <h4 class="mt-5 mb-4">
          <strong>{{ $t('CEPG_title4') }}</strong>
        </h4>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('CEPG_subtitle4_1') }}</strong>
        </h5>
        <p class="numText2">{{ $t('CEPG_sub4_1_desc1') }}<br /></p>

        <p class="numText2">{{ $t('CEPG_sub4_1_desc2') }}<br /></p>

        <p class="numText2">
          {{ $t('CEPG_sub4_1_desc3') }}
        </p>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('CEPG_subtitle4_2') }}</strong>
        </h5>
        <p class="numText2">{{ $t('CEPG_sub4_2_desc1') }}</p>
        <p class="numText3">{{ $t('CEPG_sub4_2_desc2') }}<br /></p>

        <p class="numText3">{{ $t('CEPG_sub4_2_desc3') }}<br /></p>

        <p class="numText3">{{ $t('CEPG_sub4_2_desc4') }}<br /></p>

        <p class="numText3">{{ $t('CEPG_sub4_2_desc5') }}<br /></p>
        <p class="numText3">
          {{ $t('CEPG_sub4_2_desc6') }}
        </p>
        <p class="numText2">{{ $t('CEPG_sub4_2_desc7') }}</p>
        <p class="numText3">{{ $t('CEPG_sub4_2_desc8') }}<br /></p>

        <p class="numText3">{{ $t('CEPG_sub4_2_desc9') }}<br /></p>

        <p class="numText3">
          {{ $t('CEPG_sub4_2_desc10') }}
        </p>
        <p class="numText2">{{ $t('CEPG_sub4_2_desc11') }}</p>
        <p class="numText3">
          {{ $t('CEPG_sub4_2_desc12') }}
        </p>
        <p class="numText3"></p>
        <p class="numText3">{{ $t('CEPG_sub4_2_desc13') }}<br /></p>
        <p class="numText3">{{ $t('CEPG_sub4_2_desc14') }}<br /></p>

        <p class="numText3">{{ $t('CEPG_sub4_2_desc15') }}<br /></p>

        <p class="numText3">
          {{ $t('CEPG_sub4_2_desc16') }}
        </p>
        <h5 class="mt-5 mb-3">
          <strong>{{ $t('CEPG_subtitle4_3') }}</strong>
        </h5>
        <p class="numText3" v-html="$t('CEPG_sub4_3_desc1')"><br /></p>

        <p class="numText3">{{ $t('CEPG_sub4_3_desc2') }}<br /></p>
        <p class="numText3">{{ $t('CEPG_sub4_3_desc3') }}<br /></p>
        <p class="numText3">
          {{ $t('CEPG_sub4_3_desc4') }}
        </p>
        <p class="mt-5 mb-3">
          <strong>{{ $t('CEPG_add_title') }}</strong>
        </p>
        <p class="numText13">{{ $t('CEPG_add_subtitle1') }}</p>
        <p class="numText3">{{ $t('CEPG_add_sub1_desc1') }}<br /></p>

        <p class="numText3">{{ $t('CEPG_add_sub1_desc2') }}<br /></p>
        <p class="numText3">{{ $t('CEPG_add_sub1_desc3') }}<br /></p>
        <p class="numText3">{{ $t('CEPG_add_sub1_desc4') }}</p>
        <p class="numText1">{{ $t('CEPG_add_subtitle2') }}</p>
        <p class="numText3 mb-5">{{ $t('CEPG_add_sub2_desc1') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Practiceguideline',
  data() {
    return {
      isLang: 0,
    };
  },
  methods: {
    refreshLocale(newVal) {
      if (newVal === 'ko') {
        this.isLang = 0;
      } else if (newVal === 'en') {
        this.isLang = 1;
      } else if (newVal === 'ja') {
        this.isLang = 2;
      } else {
        this.isLang = 0;
      }
    },
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      this.refreshLocale(newVal);
    },
  },
  created() {
    this.refreshLocale(this.$i18n.locale);
  },
};
</script>

<style scoped lang="scss">
p {
  word-break: keep-all;
}
.numText0 {
  margin-left: 1.5rem;
  text-indent: -1.5rem;
}
.numText1 {
  margin-left: 1rem;
  text-indent: -0.7rem;
}
.numText2 {
  margin-left: 1rem;

  text-indent: -1rem;
}
.numText3 {
  margin-left: 2rem;
  text-indent: -0.6rem;
}
</style>

<i18n>
{
  "ko": {
    "CEPG_main_title": "윤리규범 실천지침",
    "CEPG_main_desc": "공정하고 투명한 기업경영활동을 통해 사회로부터 신뢰받고 존경받는 일류 수준의 기업을 만들겠습니다.",
    "CEPG_title1": "1. 총칙",
    "CEPG_subtitle1_1": "1.1 목적",
    "CEPG_sub1_1_desc1": "본 ‘윤리규범 실천지침’ (이하 ‘실천지침’)은 SK핀크스 주식회사의 구성원이 윤리규범을 올바르게 이해하고 실천할 수 있도록 업무수행 과정에서 발생할 수 있는 윤리적 갈등상황에 대해 의사결정과 행동의 판단기준을 제공함에 그 목적이 있다.",
    "CEPG_subtitle1_2": "1.2 윤리적 의사결정 및 행동원칙",
    "CEPG_sub1_2_desc1": "구성원은 직무수행과 관련하여 윤리적 갈등상황에 놓이게 될 경우 윤리규범과 본 실천지침을 기준으로 판단하고 행동하여야 한다. 단, 실천지침에 판단기준이 명확하게 규정되어 있지 않은 경우 다음의 의사결정원칙에 따라 판단하고 행동하여야 하며, 자신의 판단에 확신이 없는 경우에는 조직의 리더 또는 윤리경영 담당부서의 자문에 따라 행동하여야 한다.",
    "CEPG_sub1_2_desc2": "• 합법성 : 자신의 행동이 법규 또는 사규 위반으로 해석될 가능성이 있는가?",
    "CEPG_sub1_2_desc3": "• 투명성 : 자신의 의사결정 과정과 내용을 공개할 수 있는가?",
    "CEPG_sub1_2_desc4": "• 합리성 : 다른 구성원들도 동일한 상황에서 같은 결정을 할 것인가?",
    "CEPG_subtitle1_3": "1.3 적용대상",
    "CEPG_sub1_3_desc1": "본 실천지침은 SK핀크스 주식회사에 재직중인 임직원을 대상으로 한다.",
    "CEPG_title2": "2. 구성원의 자세",
    "CEPG_subtitle2_1": "2.1 성실한 업무수행",
    "CEPG_sub2_1_desc1": "구성원은 자신의 권한과 책임을 명확히 인식하고 信義誠實의 원칙에 따라 직무를 수행하여야 한다.",
    "CEPG_subtitle2_2": "2.2 利害相衝의 해결",
    "CEPG_sub2_2_desc1": "1) 구성원은 직무수행과 관련하여 회사와의 利害相衝으로 회사에 대한 信義誠實 의무를 위반할 가능성이 있는 상황이 발생하지 않도록 최선을 다하여야 한다.",
    "CEPG_sub2_2_desc2": "2) 유의하여야 할 이해상충 행위는 다음과 같다. 단, 이는 모든 이해상충의 경우를 열거한 것은 아니므로 실제 상황에서는 구성원들의 엄격한 판단과 적용이 요구된다.",
    "CEPG_sub2_2_desc3": "• 행위가 금지되는 경우",
    "CEPG_sub2_2_desc4": "- 회사의 자산이나 경영정보를 개인적인 용도로 이용하여 사적 이익을 취하는 행위",
    "CEPG_sub2_2_desc5": "- 업무상 지위를 남용하여 협력회사 등에 대해 인사청탁, 각종 편의 제공 요구 등 부당한 영향력을 행사하는 행위",
    "CEPG_sub2_2_desc6": "- 성실한 업무수행을 저해할 정도의 노력이 소모되는 부업활동 등",
    "CEPG_sub2_2_desc7": "• 조직의 리더에게 이해상충 내용을 공개하고, 전결규정 등 관련 절차에 따라 회사의 승인을 득하여야 하는 경우",
    "CEPG_sub2_2_desc8": "- 직접 또는 제3자를 통하여 회사와 계약 또는 거래하는 행위",
    "CEPG_sub2_2_desc9": "- 업무를 통하여 직·간접적으로 영향력을 행사할 수 있는 협력회사의 주식을 보유하거나 경영진의 직무를 수행하는 행위 등",
    "CEPG_subtitle2_3": "2.3 회사자산 및 정보의 보호와 적절한 사용",
    "CEPG_sub2_3_desc1": "구성원은 회사의 유·무형자산을 보호하고 정당하게 사용하여야 한다.",
    "CEPG_sub2_3_desc2": "1) 회사의 자산을 승인 없이 개인적인 이익을 위하여 이용하거나 제3자에게 양도 또는 대여하여서는 아니 된다.",
    "CEPG_sub2_3_desc3": "2) 회사의 예산을 개인적인 용도로 사용하지 않으며, 회사가 정한 목적에 맞게 지출하고 회계장부에 정확하게 기록하여야 한다.",
    "CEPG_sub2_3_desc4": "3) PC, 인터넷, 전자메일, 전화/팩스 등을 개인적인 용도로 과도하게 사용하여서는 아니 된다.",
    "CEPG_sub2_3_desc5": "4) 회사의 특허권, 영업권, 상표권, 저작권, 기타 자산가치가 존재하는 정보 등의 지적재산권을 적극적으로 보호하여야 한다. 또한 타인 또는 타사의 지적재산권도 동일하게 보호하여야 하며, 특히 불법 소프트웨어를 사용해서는 아니 된다.",
    "CEPG_sub2_3_desc6": "5) 사업정보 및 기술정보를 포함한 회사의 정보자산을 보안관리규정에 따라 엄격히 관리하여야 한다. 정보자산의 공개여부 및 범위는 관련 법규 및 사규, 회사방침에 따라 결정되며 이는 외부 언론과의 인터뷰에도 동일하게 적용된다.",
    "CEPG_subtitle2_4": "2.4 선물, 접대 등의 授受 및 부정청탁 금지",
    "CEPG_sub2_4_desc1": "1) 구성원은 모든 경영활동을 공정하고 투명하게 수행하여야 한다. 따라서, 부당하고 불공정한 이익의 향유를 목적으로 이해관계자에게 금품이나 향응 등을 제공하여서는 아니 된다. 단, 상호간의 건전한 관계를 유지하기 위해 이해관계자와 법률 및 사회 통념상 인정되는 범위 내에서 합리적인 수준의 선물이나 접대 등을 줄 수 있으며, 이에 대한 세부지침은 조직 단위로 제정하여 운용할 수 있다.",
    "CEPG_sub2_4_desc2": "2) 구성원은 협력회사 등 이해관계자들로부터 금품, 향응 또는 기타 개인적인 편의 등을 받아서는 아니 된다.",
    "CEPG_sub2_4_desc3": "3) 구성원은 부정청탁을 하거나 받아서는 아니 된다.",
    "CEPG_subtitle2_5": "2.5 구성원간의 상호 존중",
    "CEPG_sub2_5_desc1": "1) 구성원은 동료 및 상하간에 서로 존중하고 직장생활에 필요한 기본예의와 SK 구성원으로서의 품위를 지켜야 한다.",
    "CEPG_sub2_5_desc2": "2) 性, 學歷, 출신지역, 결혼, 인종, 국적, 종교 등을 이유로 차별대우를 하지 않아야 한다.",
    "CEPG_sub2_5_desc3": "3) 개인의 인권을 침해하고 근무 분위기를 저해할 수 있는 일체의 성희롱 행위를 하여서는 아니 된다.",
    "CEPG_title3": "3. 법규 및 회사 경영방침의 준수",
    "CEPG_desc2": "구성원은 회사 경영활동과 관련된 국내·외 법률 및 회사 방침과 사규를 명확하게 숙지하고 이에 따라 업무를 수행하여야 한다.",
    "CEPG_subtitle3_1": "3.1 경영정보의 작성과 공개",
    "CEPG_sub3_1_desc1": "1) 회계 정보를 일반적으로 인정되는 회계원칙 및 관련 법규, 사규에 따라 정확하게 기록하고 유지하여야 하며 회계정보를 왜곡하거나 숨겨서는 아니 된다.",
    "CEPG_sub3_1_desc2": "2) 재무 정보를 포함한 회사의 모든 경영정보는 관련 사실 또는 거래내역을 정확히 반영하여 경영진의 의사결정을 뒷받침할 수 있어야 한다.",
    "CEPG_sub3_1_desc3": "3) 외부 이해관계자에 대한 경영 정보의 공개는 관련 법규 및 사규에 따라 투명하고 공정하게 이루어져야 한다.",
    "CEPG_subtitle3_2": "3.2 공정한 거래와 경쟁",
    "CEPG_sub3_2_desc1": "1) 공정거래 관련법규 및 회사의 공정거래자율준수 관리규정을 명확히 이해하고 준수하여야 한다.",
    "CEPG_sub3_2_desc2": "2) 공정거래 관련 법규 위반으로 해석될 가능성이 있는 사항에 대해서는 공정거래 또는 법무 담당부서와 사전에 충분한 협의를 거쳐 처리하여야 하며 자의적인 해석에 따라 의사결정을 하여서는 아니 된다.",
    "CEPG_subtitle3_3": "3.3 안전·보건·환경에 대한 방침",
    "CEPG_sub3_3_desc1": "안전·보건·환경과 관련된 국내·외 법규 및 국제협약을 준수하고, 안전·보건·환경 성과의 지속적 개선을 추구하여야 한다.",
    "CEPG_title4": "4. 실천지침의 운영",
    "CEPG_subtitle4_1": "4.1 책임",
    "CEPG_sub4_1_desc1": "1) 모든 구성원들은 윤리규범과 실천지침을 준수할 책임이 있으며, 의문이 있는 사항에 대해서는 조직의 리더 또는 윤리경영 담당부서에 질의·상담하여 그 해석에 따라 행동하여야 한다.",
    "CEPG_sub4_1_desc2": "2) 조직의 리더는 소속 구성원과 업무상 관련된 이해관계자들이 회사의 윤리규범과 실천지침을 올바르게 이해하고 준수할 수 있도록 적극적으로 지원하고 관리할 책임이 있다. 또한, 성실하고 책임있는 의사결정과 행동을 통하여 윤리규범과 실천지침을 준수함으로써 타의 모범을 보여야 한다.",
    "CEPG_sub4_1_desc3": "3) 윤리규범과 실천지침의 위반사항을 알게 된 구성원은 이를 조직의 리더나 윤리경영 담당부서에 보고 또는 제보하여 조속히 해결할 수 있도록 함으로써, 해당 행위로부터 회사와 구성원을 적극적으로 보호하여야 한다.",
    "CEPG_subtitle4_2": "4.2 제보자 보호",
    "CEPG_sub4_2_desc1": "1) 제보자 보호 방침",
    "CEPG_sub4_2_desc2": "• 제보자는 정당한 제보나 이와 관련한 진술, 자료제출 등을 한 이유로 임직원이나 소속 부서 등으로부터 신분상의 불이익이나 근무 조건상의 차별을 받지 아니 한다.",
    "CEPG_sub4_2_desc3": "- 제보자는 제보로 인하여 불이익 처분을 받았을 경우, 그 시정과 부서이동 등 보호 조치를 윤리경영 담당부서에 요청할 수 있다.",
    "CEPG_sub4_2_desc4": "- 윤리경영 담당부서는 제보자로부터 시정/보호 요청이 있을 경우 제보자의 불이익이 최소화되도록 조치하고, 관련자에 대한 제재조치를 포함한 재발방지 대책을 수립/실시한다.",
    "CEPG_sub4_2_desc5": "• 회사는 제보자의 신원 및 제보 내용이 본인의 의사에 반하여 공개되지 않도록 비밀을 유지한다.",
    "CEPG_sub4_2_desc6": "• 회사는 제보자 外 제보에 대한 사실확인 과정에서 진술, 자료제공 등의 방법으로 조사에 협조한 자에 대해서도 제보자와 동등하게 보호한다.",
    "CEPG_sub4_2_desc7": "2) 제재 및 감면",
    "CEPG_sub4_2_desc8": "• 회사는 상기 1)에서 규정한 ‘제보자 보호 방침’을 위반한 자를 징계 관련 규정에 따라 조치한다. 본 조치대상이 되는 주요 행위는 다음과 같다.",
    "CEPG_sub4_2_desc9": "- 제보자에게 제보로 인하여 불이익을 주는 보복조치, 직무상 또는 우연히 인지한 제보자 신원이나 제보 내용의 누설, 제보자를 색출하거나 색출을 지시하는 행위 등",
    "CEPG_sub4_2_desc10": "• 윤리규범 또는 실천지침에 저촉되는 활동 또는 위법 행위에 가담하였으나, 이에 대한 사실을 자진 신고한 경우에는 그 행위에 대한 처벌이나 징계 시 정상을 참작할 수 있다.",
    "CEPG_sub4_2_desc11": "3) 제보조사 수행지침",
    "CEPG_sub4_2_desc12": "• 제보조사 담당자 및 부서장은 업무를 수행함에 있어 제보자 보호를 최우선 원칙으로 하여 조사를 진행하여야 하며, 아래 사항을 반드시 준수해야 한다.",
    "CEPG_sub4_2_desc13": "• 제보자의 동의 없이 제보와 관련된 어떠한 정보나 내용도 유출하거나 암시할 수 없다.",
    "CEPG_sub4_2_desc14": "• 제보 관련 모든 자료는 허가된 자에게만 열람하며 철저한 보안이 유지되도록 보관하되, 우편 등으로 접수된 종이문서는 시건 장치가 유지된 별도의 보관장소에 보관하여야 한다.",
    "CEPG_sub4_2_desc15": "• 조사수행 과정에서 인지한 제보자의 신원이나 제보내용을 공개 또는 누설하는 자를 징계 관련 규정에 따라 조치하되, 하기 예외 사항 등 관련 사안의 공개가 제보자의 신원이나 제보내용의 보호보다 중요한 경우 면책할 수 있다. 다만, 이 경우에도 공개의 범위는 필요 최소한이어야 하며, 그 범위를 넘어서는 공개는 면책되지 아니한다.",
    "CEPG_sub4_2_desc16": "- 심각한 인명 안전사고 및 사업 중단을 초래할 수 있는 사항, 중요한 부정사건 관련 사항, 회사 기밀 유출 및 자료 위/변조 관련 사항, 언론 보도 사항 등",
    "CEPG_subtitle4_3": "4.3 윤리상담 및 제보 채널",
    "CEPG_sub4_3_desc1": "- 홈페이지 : <a href='https://ethics.sk.co.kr'>https://ethics.sk.co.kr</a>",
    "CEPG_sub4_3_desc2": "- 전화 : 064-793-5014",
    "CEPG_sub4_3_desc3": "- 이메일 : pinxethics@skpinx.co.kr",
    "CEPG_sub4_3_desc4": "- 우편 : (63525) 제주특별자치도 서귀포시 안덕면 산록남로 863 SK핀크스㈜ ESG TF 윤리경영 담당자",
    "CEPG_add_title": "부칙 (2021.3.29)",
    "CEPG_add_subtitle1": "1. 시행일",
    "CEPG_add_sub1_desc1": "본 실천지침은 2011년 3월 11일부로 시행한다.",
    "CEPG_add_sub1_desc2": "본 실천지침은 2018년 2월 21일부로 시행한다.",
    "CEPG_add_sub1_desc3": "본 실천지침은 2018년 7월 17일부로 시행한다.",
    "CEPG_add_sub1_desc4": "본 실천지침은 2021년 3월 29일부로 시행한다.",
    "CEPG_add_subtitle2": "2. 위반에 대한 조치",
    "CEPG_add_sub2_desc1": "본 실천지침의 위반사항은 사규에 따라 처리한다."
  },
  "en": {
    "CEPG_main_title": "Code of Ethics Practice Guidelines",
    "CEPG_main_desc": "We will create a first-class company that is trusted and respected by society through fair and transparent corporate management activities",
    "CEPG_title1": "1. General Provisions",
    "CEPG_subtitle1_1": "1.1 Objective",
    "CEPG_sub1_1_desc1": "The Code of Ethics Guidelines (hereinafterthese“Guidelines”) aim to provide the criteria for decision making and action regarding ethical dilemmas, which may arise in the course of work, to assist the Members of SKPINX CO.,LTD to understand and properly act upon the Company’s Code of Ethics.",
    "CEPG_subtitle1_2": "1.2 Principles of ethical decision-making and conduct",
    "CEPG_sub1_2_desc1": "All Members shall act in accordance with the Code of Ethics and these Guidelines in case of an ethical dilemma faced in the course of work. In case these Guidelines lack clear guidance for a particular case, Members shall act in accordance with the following principles of decision-making, and if one is uncertain of his or her judgment, such Member shall seek the advice of his or her leader or that of the ethics department for correct interpretation and shall act in accordance with such interpretation.",
    "CEPG_sub1_2_desc2": "• Legitimacy : Can the action be interpreted as a violation of laws/regulations or the Company regulations?",
    "CEPG_sub1_2_desc3": "• Transparency : Can the process of decision-making and outcome be disclosed?",
    "CEPG_sub1_2_desc4": "• Rationality : Would other Members make the same decision under the same circumstances?",
    "CEPG_subtitle1_3": "1.3 Subject for Application",
    "CEPG_sub1_3_desc1": "These Guidelines apply to all Members of SK PINX Co., Ltd.",
    "CEPG_title2": "2. Responsibility of the Members",
    "CEPG_subtitle2_1": "2.1 Diligent execution of duties",
    "CEPG_sub2_1_desc1": "Members must be fully aware of their rights and responsibilities and work in good faith.",
    "CEPG_subtitle2_2": "2.2 Resolution of Conflicts of Interest",
    "CEPG_sub2_2_desc1": "1) Members must do their best to prevent the circumstances that may violate their duty of loyalty to the Company due to the conflict of interest with the Company in relation to the performance of their duties.",
    "CEPG_sub2_2_desc2": "2) The following circumstances shall be considered as conflict of interest. Members shall note that since this is not an exhaustive list of all conflict of interest cases, strict judgement and application of these Guidelines for actual cases are required.",
    "CEPG_sub2_2_desc3": "• Prohibited Actions",
    "CEPG_sub2_2_desc4": "- Using the Company’s assets or information to obtain personal gains;",
    "CEPG_sub2_2_desc5": "- Abusing one’s position in the Company as means to exert improper influence on the Company’s business partners such as solicitation of any personal management, request for provision of various advantages, etc.; and",
    "CEPG_sub2_2_desc6": "- Engaging in secondary jobs to such extent that it impedes one’s diligent execution of duties.",
    "CEPG_sub2_2_desc7": "• Cases where one must disclose conflict of interest to his/her leader and obtain the Company’s approval in accordance with the relevant Company procedures such as the arbitrary decision regulation:",
    "CEPG_sub2_2_desc8": "- One directly or indirectly enters into a contract with, or engages in business transactions with, the Company; and",
    "CEPG_sub2_2_desc9": "- One owns shares of, or engages in management activities of, the Company’s business partner, which can be put under his or her direct or indirect influence through business relations.",
    "CEPG_subtitle2_3": "2.3 Appropriate use and protection of the Company’s assets and information",
    "CEPG_sub2_3_desc1": "Members must safeguard and properly use the Company’s tangible and intangible assets.",
    "CEPG_sub2_3_desc2": "1) One shall not use the Company’s assets or transfer/lease the Company’s assets to a third party for personal gains without the Company’s authorization or approval.",
    "CEPG_sub2_3_desc3": "2) One shall not use the Company’s budget for personal use. The Company’s budget must be used only for the purpose set by the Company, and such use must be accurately recorded in the Company’s accounting books.",
    "CEPG_sub2_3_desc4": "3) One shall not exploit the Company’s computers, Internet, e-mail, phone, fax, etc. for personal use.",
    "CEPG_sub2_3_desc5": "4) One shall strictly protect the Company’s intellectual property rights, including but not limited to patents, trade secrets, trademarks, copyrights, and other proprietary information. The intellectual property rights of third parties shall also receive the same protection, and in particular, illegal software shall not be used.",
    "CEPG_sub2_3_desc6": "5) The Company’s proprietary information, including information related to business or technology, shall be strictly managed in accordance with the related Company regulations. The decision of whether or not to disclose information assets and the scope of such disclosure shall be determined according to the relevant laws/regulations and the Company regulations and policy, and the same applies to the interviews with the media.",
    "CEPG_subtitle2_4": "2.4 Prohibition of Bribery, Entertainment, and Unfair Solicitation",
    "CEPG_sub2_4_desc1": "1) Members shall conduct all business activities in a fair and transparent manner.  Therefore, providing money or gifts to stakeholders for the purpose of gaining unfair and unjust benefits is strictly prohibited. whether delivered directly or indirectly, are prohibited, except such offer or receipt of reasonable level of hospitalities within the scope generally permitted under the relevant laws/regulations and in the context of social norms to maintain healthy and friendly relationship. Each department or division in the Company may establish and enforce more detailed guidelines for the foregoing in accordance with the relevant laws/regulations.",
    "CEPG_sub2_4_desc2": "2) Members Shall not receive money, entertainment, or other personal convenience from stakeholders such as cooperative companies.",
    "CEPG_sub2_4_desc3": "3) Members Shall neither engage in nor accept unfair solicitation.",
    "CEPG_subtitle2_5": "2.5 Mutual respect between the Members of the Company",
    "CEPG_sub2_5_desc1": "1) Members shall treat each other with respect and maintain propriety and dignity as SK members.",
    "CEPG_sub2_5_desc2": "2) No Member shall be discriminated against for the reason of gender, educational background, birthplace, marital status, race, nationality, religion, etc.",
    "CEPG_sub2_5_desc3": "3) Members shall not engage in any acts (i.e. sexual harassment, workplace bullying, etc.) that violate a person’s human rights and disrupts a healthy work environment.",
    "CEPG_title3": "3. Compliance with Laws/Regulations and Business Policy ",
    "CEPG_desc2": "Members shall have clear understanding of domestic and foreign laws related to the Company’s business activities as well as the Company’s policy and other regulations and shall perform their duties accordingly.",
    "CEPG_subtitle3_1": "3.1 Preparation and disclosure of management information ",
    "CEPG_sub3_1_desc1": "1) Accounting practice shall strictly adhere to the generally accepted accounting principles, related laws/regulations and the Company regulations to accurately record and maintain the accounting information. Accounting information of the Company shall not be distorted or concealed in any way.",
    "CEPG_sub3_1_desc2": "2) All management information including financial information shall accurately reflect all relevant facts and details of business transactions to support the management in making business decisions.",
    "CEPG_sub3_1_desc3": "3) The disclosure of management information to the external interested parties shall be made in a fair and transparent manner according to the relevant laws/regulations and the Company regulations.",
    "CEPG_subtitle3_2": "3.2 Fair Trade and Competition",
    "CEPG_sub3_2_desc1": "1) Members shall have clear understanding of, and comply with, the laws related to fair trade and competition as well as the Company’s fair trade voluntary compliance management regulation.",
    "CEPG_sub3_2_desc2": "2) For matters that may be interpreted as violation of fair trade and competitions laws, Members must seek prior consultation of the compliance or legal team of the Company to handle such matters and Members shall not make any arbitrary decisions with regards to such matters.",
    "CEPG_subtitle3_3": "3.3 Policies on safety, health, and environment",
    "CEPG_sub3_3_desc1": "Members shall observe domestic and foreign laws/regulations and international treaties related to safety, health, and environment. Members shall strive to continuously make improvements in these domains.",
    "CEPG_title4": "4. Execution of these Guidelines",
    "CEPG_subtitle4_1": "4.1 Responsibility",
    "CEPG_sub4_1_desc1": "1) All Members are obligated to comply with the Code of Ethics and these Guidelines, and shall seek and follow interpretation of his/her leader or ethics management department of the Company for any matters that are questionable.",
    "CEPG_sub4_1_desc2": "2) Leaders shall ensure that their members and other interested parties related to their work have a sufficient understanding of the Company’s Code of Ethics and these Guidelines. Also, leaders shall diligently and responsibly observe the Code of Ethics and these Guidelines in their decision making and actions at all times to set standards for the conducts of other Members.",
    "CEPG_sub4_1_desc3": "3) Members who become aware of violations of code of ethics and its practice guidelines should promptly report or disclose such violations to organizational leaders or the ethics management department for swift resolution. They should actively protect the company and fellow members from the consequences of such violations.",
    "CEPG_subtitle4_2": "4.2 Whistle-blower Protection",
    "CEPG_sub4_2_desc1": "1) Whistle-blower Protection Policy",
    "CEPG_sub4_2_desc2": "• Whistle-blowers shall not suffer any identity-based disadvantages or discriminatory treatment in working conditions from employees or departments due to legitimate whistle-blowing, statements, or the submission of related evidence.",
    "CEPG_sub4_2_desc3": "- If a whistle-blower faces adverse consequences due to the whistle-blowing, they may request corrective actions and protective measures such as remediation and departmental transfers from the ethics management department.",
    "CEPG_sub4_2_desc4": "- Upon receiving a request for correction/protection from a whistle-blower, the ethics management department shall take measures to minimize any adverse consequences for the whistle-blower, develop and implement preventive measures, including sanctions against relevant parties, to prevent recurrence.",
    "CEPG_sub4_2_desc5": "• The company maintains confidentiality to ensure that the identity and the content of the whistle-blowing are not disclosed contrary to their intentions.",
    "CEPG_sub4_2_desc6": "• The company equally protects individuals who cooperate in the investigation process, providing statements or information during the fact-finding of the whistle-blower, in addition to the whistle-blower.",
    "CEPG_sub4_2_desc7": "2) Sanctions and Exemptions",
    "CEPG_sub4_2_desc8": "• The company takes measures in accordance with disciplinary regulations against individuals who violate the 'Whistle-blower Protection Policy' as stipulated in the above 1). The key actions subject to these measures include:",
    "CEPG_sub4_2_desc9": "- Retaliatory actions causing detriment to the whistle-blower due to the disclosure, disclosure of the whistle-blower's identity or the content of the disclosure that is learned either through official duties or coincidentally, and actions to identify or instruct the identification of the whistleblower.",
    "CEPG_sub4_2_desc10": "• In cases where individuals engage in activities that violate code of ethics or its practice guidelines or participate in unlawful actions but voluntarily report such activities, the company may take into consideration of the extenuating circumstances when imposing sanctions or disciplinary actions for those actions.",
    "CEPG_sub4_2_desc11": "3) Whistle-blower Investigation Guidelines",
    "CEPG_sub4_2_desc12": "• Those responsible for conducting whistle-blower investigations, including the investigators and department heads, must prioritize whistle-blower protection as the paramount principle when carrying out their duties. They must adhere strictly to the following:",
    "CEPG_sub4_2_desc13": "• No information or content related to the whistle-blower's disclosure can be leaked or implied without the whistle-blower's consent.",
    "CEPG_sub4_2_desc14": "• All documentation related to the disclosure should only be accessible to authorized personnel, ensuring thorough security measures for storage. Paper documents received through mail, etc., should be stored in a separate secure location with maintained access controls.",
    "CEPG_sub4_2_desc15": "• Any individual who discloses or leaks the identity of the whistle-blower or the content of the disclosure, as identified during the investigation process, shall be subject to disciplinary measures according to relevant regulations. However, exemptions may be granted if the disclosure of related matters, including the following exceptions, is deemed more critical than the protection of the whistle-blower's identity or disclosure content. Nevertheless, even in such cases, the scope of disclosure should be the absolute minimum necessary, and any disclosure beyond that minimum shall not be exempted.",
    "CEPG_sub4_2_desc16": "- Matters that may cause serious life safety accidents and business interruption, matters related to important fraud cases, matters related to leakage of company secrets and forgery/falsification of data, media reports, etc.",
    "CEPG_subtitle4_3": "4.3 Ethics Consultation and Reporting Channels",
    "CEPG_sub4_3_desc1": "- Website: <a href='https://ethics.sk.co.kr'>https://ethics.sk.co.kr</a>",
    "CEPG_sub4_3_desc2": "- Email: pinxethics@skpinx.co.kr",
    "CEPG_sub4_3_desc3": "- Phone: 064-793-5014",
    "CEPG_sub4_3_desc4": "- Mail: SK Pinx Co., Ltd. ESG TF Ethics Management, 863 Sanroknam-ro, Andeok-myeon, Seogwipo-si, Jeju Special Self-Governing Province, Korea.",
    "CEPG_add_title": "Addenda (March 29, 2021)",
    "CEPG_add_subtitle1": "1. Effective Date",
    "CEPG_add_sub1_desc1": "These Guidelines shall come into force on March 11, 2011.",
    "CEPG_add_sub1_desc2": "These Guidelines shall come into force on February 21, 2018.",
    "CEPG_add_sub1_desc3": "These Guidelines shall come into force on July 17, 2018.",
    "CEPG_add_sub1_desc4": "These Guidelines shall come into force on March 29, 2021.",
    "CEPG_add_subtitle2": "2. Punishment against non-compliance",
    "CEPG_add_sub2_desc1": "Any violation of these Guidelines shall be treated in accordance with the Company regulations."
  },
  "ja": {

  }
}
</i18n>
